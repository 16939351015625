/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { CATEGORY } from '../../constants';

const DualPath = ({ category }) => {

  const howToGetIt = () => {
    if ([CATEGORY.PLUMBING_REPAIR, CATEGORY.WATER_HEATER_REPAIR]
      .includes(category)) {
      return (
        <Typography variant="body-base">
          You have selected expert in-home repair,
          so this item isn&#39;t available to add to your cart for pickup or delivery.
          One of our Pros will reach out to discuss professional repair service and pricing.
        </Typography>
      );
    }
    return (
      <Typography variant="body-base">
        You have selected expert in-home installation,
        so this item isn&#39;t available to add to your cart for pickup or delivery.
        One of our Pros will reach out to discuss product, professional installation and pricing.
      </Typography>
    );
  };

  return (
    <div
      data-component="FulfillmentDualPath"
      data-testid="fulfillment__unavailable"
    >
      {howToGetIt()}
    </div>
  );
};

DualPath.propTypes = {
  category: string
};

DualPath.defaultProps = {
  category: ''
};

export { DualPath };
