/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { Tile, TileContent } from '@one-thd/sui-atomic-components';
import { ConfirmedFilled } from '@one-thd/sui-icons';
import { getLocalStore } from '../helper/utils';

export const PickupTile = ({
  getWrapperClasses,
  fulfillmentObj,
  currentFulfillmentMethod,
  setSelectedFulfillmentMethod,
  isCartTiles,
  storeIdFromCart,
  lineItemId,
  originalConfigId,
  isCheckNearByStore
}) => {

  const localStoreLocation = getLocalStore(fulfillmentObj.subTemplate?.props?.fulfillment);
  const availableQuantity = localStoreLocation?.inventory?.quantity;
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    host = window.location.origin.match(/local/g)
      ? window.location.origin : 'https://www.homedepot.com';
  }
  const { storeId } = useStore();
  const { channel } = useContext(ExperienceContext);
  const {
    itemId,
    quantity,
    isShipToHomeEligible,
    addOnsAssemblyAndMore
  } = fulfillmentObj.subTemplate?.props || {};

  const cartReqParams = {
    itemId,
    quantity,
    host,
    keyword: isCartTiles ? storeIdFromCart : storeId,
    channel,
    isShipToHomeEligible,
    alterBrowserHistory: true,
    addOnsAssemblyAndMore
  };

  if (lineItemId) {
    cartReqParams.lineItemId = lineItemId;
  }

  if (originalConfigId) {
    cartReqParams.paintConfiguration = { configurationId: originalConfigId };
  }

  const pickupTileClickHandler = (event) => {
    if ((fulfillmentObj.method === currentFulfillmentMethod) || !fulfillmentObj.enabled) { return; }
    if (fulfillmentObj.isPickupNearby && fulfillmentObj.method === 'BOPIS') { return; }
    if ((Number(quantity) > availableQuantity) && !fulfillmentObj.isPickupNearby && fulfillmentObj.method === 'BOPIS') {
      event.stopPropagation();
      event.preventDefault();
      window.LIFE_CYCLE_EVENT_BUS.trigger('cart.select-store', {
        cartReqParams
      });
    } else {
      setSelectedFulfillmentMethod(fulfillmentObj.method);
    }
  };

  const isPickupSelected = fulfillmentObj.method === currentFulfillmentMethod && fulfillmentObj.enabled;
  return (
    <Tile
      value="pickupTile"
      aria-label="pickupTile"
      onClick={pickupTileClickHandler}
      selected={isPickupSelected}
      unavailable={!fulfillmentObj.enabled}
      disabled={!fulfillmentObj.enabled}
    >
      <TileContent orientation="vertical" alignItems="start" grow>
        <div className="sui-flex sui-flex-wrap">
          {fulfillmentObj.template}
          {isPickupSelected
            && (
              <div className="sui-absolute sui-top-0 sui-right-0 sui-pt-2 sui-pr-2 sui-text-brand">
                <ConfirmedFilled size="small" />
              </div>
            )}
        </div>
      </TileContent>
    </Tile>

  );
};

PickupTile.propTypes = {
  getWrapperClasses: PropTypes.func.isRequired,
  fulfillmentObj: PropTypes.object.isRequired,
  currentFulfillmentMethod: PropTypes.string.isRequired,
  setSelectedFulfillmentMethod: PropTypes.func.isRequired,
  isCartTiles: PropTypes.bool,
  storeIdFromCart: PropTypes.string,
  lineItemId: PropTypes.string,
  originalConfigId: PropTypes.string,
  isCheckNearByStore: PropTypes.bool
};

PickupTile.defaultProps = {
  isCartTiles: false,
  storeIdFromCart: null,
  lineItemId: '',
  originalConfigId: null,
  isCheckNearByStore: false
};
