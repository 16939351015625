import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { CheckNearByStore } from './partials/store/CheckNearByStore';
import { IDS } from '../../automationTestIds';

export const PickupHeader = ({
  storeName,
  itemId,
  quantity,
  originalConfigId,
  lineItemId,
  pickupUnavailable,
  isMajorAppliance,
  isCartTiles,
  localizerStoreName,
  addOnsAssemblyAndMore,
  isExchangeCustomer,
  storeId,
  leadTime,
  hasCustomATC,
  disablePickupNearBy,
  showAvailableText,
  isConfigurator,
  hideHeader
}) => {
  const displayStoreName = isCartTiles ? (storeName || localizerStoreName) : storeName;

  const storeNameComponent = (
    isConfigurator
    || isMajorAppliance
    || disablePickupNearBy
    || (isCartTiles && isExchangeCustomer)
  )
    ? displayStoreName
    : (
      <CheckNearByStore
        itemId={itemId}
        quantity={quantity}
        originalConfigId={originalConfigId}
        lineItemId={lineItemId}
        addOnsAssemblyAndMore={addOnsAssemblyAndMore}
        isCartTiles={isCartTiles}
        storeIdFromCart={storeId}
        leadTime={leadTime}
        hasCustomATC={hasCustomATC}
        configuratorCart={isConfigurator}
        storeNameLink
      >
        {displayStoreName}
      </CheckNearByStore>
    );

  return (
    <div
      data-testid="pickup-header"
      className="sui-flex-row sui-w-1/2"
      data-automation-id={pickupUnavailable ? IDS.UA.pickup_unavailable : IDS.LABEL.pickupLabel}
    >
      {!hideHeader && (
        <Typography variant="body-base" height="normal">
          {pickupUnavailable ? (
            <span className="sui-font-bold">Unavailable</span>
          ) : (
            (showAvailableText && 'Available') || 'Pickup'
          )}
          {displayStoreName && (
            <>
              {' at '}
              {storeNameComponent}
            </>
          )}
        </Typography>
      )}
    </div>
  );
};

PickupHeader.propTypes = {
  storeName: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  pickupUnavailable: PropTypes.bool,
  isMajorAppliance: PropTypes.bool,
  isCartTiles: PropTypes.bool,
  localizerStoreName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: PropTypes.object,
  isExchangeCustomer: PropTypes.bool,
  hideHeader: PropTypes.bool,
  storeId: PropTypes.string,
  lineItemId: PropTypes.string,
  quantity: PropTypes.number,
  leadTime: PropTypes.number,
  hasCustomATC: PropTypes.bool,
  originalConfigId: PropTypes.string,
  disablePickupNearBy: PropTypes.bool,
  showAvailableText: PropTypes.bool,
  isConfigurator: PropTypes.bool
};

PickupHeader.defaultProps = {
  storeName: '',
  pickupUnavailable: false,
  isMajorAppliance: false,
  isCartTiles: false,
  localizerStoreName: '',
  addOnsAssemblyAndMore: null,
  isExchangeCustomer: false,
  hideHeader: false,
  storeId: '',
  lineItemId: '',
  quantity: 1,
  leadTime: 0,
  hasCustomATC: null,
  originalConfigId: null,
  disablePickupNearBy: false,
  showAvailableText: false,
  isConfigurator: false
};
