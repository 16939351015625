import React from 'react';
import {
  shape,
  string,
  bool,
  PropTypes
} from 'prop-types';
import { TrackOutOfStock } from '@thd-olt-component-react/email-signup';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { useTheme } from '@thd-olt-component-react/theme-provider';

const NotifyMe = (props) => {
  const {
    isMajorAppliance,
    showCheckAvailability,
    setShowCheckAvailability,
    itemId,
    store,
    shipping,
    skipEmailOptIn
  } = props;

  const theme = useTheme(NotifyMe);
  const { showTrackOutOfStock } = theme.props;

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
  };

  const availabilityButton = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      classNamecomponent="span"
      onClick={handleOnClick}
    >
      Change Delivery Zip Code
    </Link>
  );

  if (isMajorAppliance) {
    return (
      <div
        className="sui-max-w-md"
        data-testid="fulfillment__unavailable"
        data-component="FulfillmentNotifyMe"
      >
        <Typography
          variant="h3"
          weight="bold"
          height="tight"
          data-automation-id="fulfillment__out-of-stock"
        >
          Out of Stock
        </Typography>
        <Typography variant="body-base">
          <div>This product is currently out of stock.</div>
        </Typography>
        <span>{availabilityButton}</span>
      </div>
    );
  }

  const noStockAvailable = !shipping.enabled && !store.enabled && shipping.outOfStock;

  return (
    <div
      className="sui-max-w-md"
      data-testid="fulfillment__unavailable"
      data-component="FulfillmentNotifyMe"
    >
      <Typography variant="h3" data-automation-id="fulfillment__out-of-stock">
        Out of Stock
      </Typography>
      {showTrackOutOfStock && !skipEmailOptIn && (shipping?.showNotify || noStockAvailable) ? (
        <div>
          <TrackOutOfStock itemId={itemId} />
        </div>
      ) : (
        <div className="sui-pt-2">
          <Typography variant="body-base">This item is currently unavailable.</Typography>
        </div>
      )}
    </div>
  );
};

NotifyMe.propTypes = {
  isMajorAppliance: PropTypes.bool,
  showCheckAvailability: PropTypes.bool.isRequired,
  setShowCheckAvailability: PropTypes.func.isRequired,
  itemId: string,
  store: shape({
    method: string,
    unavailable: bool
  }),
  shipping: shape({
    method: string,
    unavailable: bool
  }),
  skipEmailOptIn: bool
};

NotifyMe.defaultProps = {
  itemId: null,
  store: null,
  shipping: null,
  skipEmailOptIn: false,
  isMajorAppliance: false
};

NotifyMe.themeProps = {
  showTrackOutOfStock: bool
};

NotifyMe.defaultThemeProps = {
  showTrackOutOfStock: true
};

NotifyMe.displayName = 'NotifyMe';

export { NotifyMe };
