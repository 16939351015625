import React from 'react';
import { string, bool } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  LIVE_GOODS_IN_SEASON_NEW,
  LIVE_GOODS_OUT_OF_SEASON_NEW
} from '../../constants';

const LiveGoods = ({ storeName, seasonStatusEligible }) => {
  return (
    <div data-testid="fulfillment__unavailable" data-component="FulfillmentLiveGoods">
      <div>
        {seasonStatusEligible && (
          <>
            <Typography variant="h3" height="tight" color="success">{LIVE_GOODS_IN_SEASON_NEW}</Typography>
            <Typography variant="body-base">
              <div className="sui-pt-2">
                <span>Stop by&nbsp;</span>
                <span className="sui-font-bold">{storeName}</span>
                <span>&nbsp;to see this variety and more in person.</span>
              </div>
            </Typography>
          </>
        )}
        {!seasonStatusEligible && (
          <>
            <Typography variant="h3" height="tight">{LIVE_GOODS_OUT_OF_SEASON_NEW}</Typography>
            <Typography variant="body-base">
              <div className="sui-pt-2">
                <span>Visit&nbsp;</span>
                <span className="sui-font-bold">{storeName}</span>
                <span>&nbsp;next season to find this plant and more.</span>
              </div>
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

LiveGoods.propTypes = {
  storeName: string,
  seasonStatusEligible: bool
};

LiveGoods.defaultProps = {
  storeName: null,
  seasonStatusEligible: false
};

export { LiveGoods };
