/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { node, bool } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

const TileFooter = ({ children, isDelivery }) => {

  return (
    <div className={`sui-pt-2 sui-min-h-[24px] ${isDelivery ? 'sui-pb-2' : ''}`}>
      <Typography data-testid="card-messaging-bottom" variant="body-base" weight="bold" color="success">
        {children}
      </Typography>
    </div>

  );
};
TileFooter.propTypes = {
  children: node,
  isDelivery: bool
};
TileFooter.defaultProps = {
  children: null,
  isDelivery: false
};

export { TileFooter };
