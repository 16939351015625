import React from 'react';
import PropTypes from 'prop-types';
import './delivery-options-drawer.scss';
import { Drawer, DrawerHeader, DrawerBody, Typography } from '@one-thd/sui-atomic-components';
import {
  capitalizeFirstLetter,
  formatDate,
  formatDateWithDayOfWeek,
  getDeliveryDateAvailability,
  isDeliveryTimelineTodayOrTomorrow,
  isDeliveryTimelineToday,
  getShipModeDetails,
  sortedServices,
  groupServicesByType,
} from '../../helpers/utils';
import { DeliveryOptionCard } from './DeliveryOptionCard';
import {
  BODFS_DELIVERY,
  DRAWER_HEADER,
  SHIP_TO_HOME,
  SIX_HOUR_CAR_BODFS_MESSAGE,
  CHOOSE_BODFS_MESSAGE,
  TOMORROW_SCHEDULE_DELIVERY_TEXT,
  CHOOSE_OPTION_TEXT,
  EXPRESS_DELIVERY,
} from '../../helpers/constants';
import { useTheme } from '@thd-olt-component-react/theme-provider';

export const DeliveryDrawer = ({
  sthServices,
  bodfsService,
  isQuickViewOpen,
  handleOnClose,
  channel,
  showTodayOrTomorrow = null,
}) => {
  
  const checkTodayTomorrowDate = (startDate, endDate, formatDateWithRange = null) => {
    if (startDate === endDate && showTodayOrTomorrow) {
      return showTodayOrTomorrow(endDate, true, '', formatDate(endDate, false));
    }
    return formatDateWithRange ? formatDateWithRange(startDate, endDate) : '';
  };
  const sthDate = isDeliveryTimelineTodayOrTomorrow(sthServices[0]?.deliveryTimeline)
    ? capitalizeFirstLetter(getDeliveryDateAvailability(sthServices[0]?.deliveryTimeline)?.toLowerCase())
    : checkTodayTomorrowDate(sthServices[0]?.deliveryDates?.startDate,
      sthServices[0]?.deliveryDates?.endDate,
      formatDateWithDayOfWeek);
  const bodfsDate = getDeliveryDateAvailability(bodfsService[0]?.deliveryTimeline)?.toLowerCase();
  const hasSameDayCarDelivery = bodfsService[0]?.hasSameDayCarDelivery || false;
  const sameDayDeliveryChargeValue = bodfsService?.[0]?.sameDayDeliveryCharge;

  const renderSixHourCarMessage = () => (
    <div className={`DeliveryDrawer__deliveryEstSixHour ${channel}`}>
      <span
        className={`DeliveryDrawer--delivery-express-truck-icon DeliveryDrawer--delivery-express-truck-icon--padding
      ${channel}`}
      />
      <span className={`DeliveryDrawer__sixHour ${channel}`}>
        {SIX_HOUR_CAR_BODFS_MESSAGE}&nbsp;
      </span>
      <span
        className={`DeliveryDrawer__sixHourNext ${channel}`}
      >{CHOOSE_BODFS_MESSAGE}
      </span>
    </div>
  );
  const renderBODFSMessage = () => (
    <div className="sui-pb-2">{BODFS_DELIVERY}&nbsp;
      <span className="sui-font-bold">{capitalizeFirstLetter(bodfsDate)}</span>
    </div>
  );
  const theme = useTheme(DeliveryDrawer);
  const { showDeliveryEst } = theme.props;
  const hideSameDayDelivery = !showDeliveryEst && (isDeliveryTimelineToday(bodfsDate) || hasSameDayCarDelivery);
  const deliveryMessage = hasSameDayCarDelivery
    ? renderSixHourCarMessage()
    : renderBODFSMessage();

    //combine, sort and group the services
    const allServices = [...bodfsService, ...sthServices];
    const allSortedServices = sortedServices(allServices);
    const groupedServiceType = groupServicesByType(allSortedServices);
    const isBigBulkySthServices = groupedServiceType[SHIP_TO_HOME] && groupedServiceType[SHIP_TO_HOME]?.length > 1;
  
  const renderTodayMessage = () => {
    return (
      <Typography variant="body-lg" height="normal">
        <div className="sui-grid sui-grid-cols-12">
          <div className="sui-col-span-10 sui-pb-1">
            {BODFS_DELIVERY}&nbsp;
            <span className="sui-font-bold">Today*</span>&nbsp;
          </div>
          <div className="sui-col-span-2 sui-text-end sui-font-bold">
            <span className="sui-text">{`$${sameDayDeliveryChargeValue}`}</span>
          </div>
        </div>
      </Typography>
    )
  }
 
  const renderDisplayMessage = (serviceType) => {
    return (
      <div data-automation-id="estimatedDate">
        <Typography variant="body-lg" height="normal">
          <div className="sui-grid sui-grid-cols-12">
            <div className="sui-col-span-10 sui-pb-1">
              {BODFS_DELIVERY}&nbsp;
              {groupedServiceType[serviceType]?.[0]?.type === EXPRESS_DELIVERY ?
                <span className="sui-font-bold">{capitalizeFirstLetter(bodfsDate)}</span> :
                <span className="sui-font-bold">{sthDate}</span>}&nbsp;
              {groupedServiceType[serviceType]?.[0]?.type === EXPRESS_DELIVERY ? TOMORROW_SCHEDULE_DELIVERY_TEXT : ''}
            </div>
            
            <div className="sui-col-span-2 sui-text-end sui-font-bold">
              {!isBigBulkySthServices || groupedServiceType[serviceType]?.[0]?.type !== SHIP_TO_HOME ?
               (groupedServiceType[serviceType]?.[0]?.totalCharge === 0
                  ? <span className="sui-text-success">FREE</span> :
                   `$${groupedServiceType[serviceType]?.[0]?.totalCharge}`) : null
                }
            </div>
          </div>
        </Typography>
      </div>
    )
  }
  
  return (
    // temporary fix for the z-index of live chat and feedback button having too high of a z-index
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <>
      <Drawer
        open={isQuickViewOpen}
        onClose={handleOnClose}
        position="right"
        className="z-index-2000000010"
      >
        <DrawerHeader title={DRAWER_HEADER} onClose={handleOnClose} />
        <DrawerBody>
          {allServices?.length > 1 && (
            <div className="sui-pb-5">
              <Typography variant="body-base" weight="bold" height="normal" align="center">
                {CHOOSE_OPTION_TEXT}
              </Typography>
            </div>
          )}
          <div data-automation-id="estimatedDelivery">
            {!hideSameDayDelivery && bodfsService?.[0]?.hasSameDayCarDelivery && (
              <div className="sui-pb-5">
                {renderTodayMessage()}
              </div>
            )}
            {Object.keys(groupedServiceType).map(serviceType => (
              <div key={serviceType}>
                <div className={serviceType === SHIP_TO_HOME && serviceType.length > 1 ? 'sui-pb-2' : 'sui-pb-5'}>
                  {renderDisplayMessage(serviceType)}
                </div>
                <div data-automation-id="cardContainer">
                  {groupedServiceType[serviceType]?.map(service => (
                    service.type === SHIP_TO_HOME && (
                      <DeliveryOptionCard
                        channel={channel}
                        deliveryType={SHIP_TO_HOME}
                        deliveryOption={service}
                        isBigBulkySthServices={isBigBulkySthServices}
                      />
                    )
                  ))}
                </div>
              </div>
            ))}
          </div>
          {bodfsService?.length > 0 && (
          <div data-automation-id="drawerFooter" className="sui-pt-3">
            <Typography variant="body-small" height="normal">
              <div className="sui-pb-1">
                <span className="sui-text-sm">
                  {getShipModeDetails()}
                </span>
              </div>
            </Typography>
          </div>
          )}
        </DrawerBody>
      </Drawer>
    </>
  );
};
DeliveryDrawer.propTypes = {
  isQuickViewOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
  sthServices: PropTypes.instanceOf(Array).isRequired,
  bodfsService: PropTypes.instanceOf(Array).isRequired,
  channel: PropTypes.string,
  showTodayOrTomorrow: PropTypes.func,
};
DeliveryDrawer.defaultProps = {
  isQuickViewOpen: false,
  handleOnClose: () => {
  },
  channel: null,
  showTodayOrTomorrow: null,
};
DeliveryDrawer.themeProps = {
  showDeliveryEst: Boolean,
};
DeliveryDrawer.defaultThemeProps = {
  showDeliveryEst: true,
};
DeliveryDrawer.displayName = 'DeliveryDrawer';