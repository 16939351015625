import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { FULFILLMENT_METHODS } from '../../constants';
import { TileHead } from './TileHead';
import { IDS } from '../../../automationTestIds';

const NotAvailable = (props) => {
  const { type } = props;
  let automationIdLabel = '';
  let automationIdStatus = '';
  const getTitle = (fulfillType) => {
    switch (fulfillType) {
    case FULFILLMENT_METHODS.APPLIANCE:
    case FULFILLMENT_METHODS.STH:
    case FULFILLMENT_METHODS.BODFS:
      automationIdLabel = IDS.LABEL.sthLabel;
      automationIdStatus = IDS.UA.sth_unavailable;
      return 'Delivery';
    case FULFILLMENT_METHODS.BOPIS:
      automationIdLabel = IDS.LABEL.pickupLabel;
      automationIdStatus = IDS.UA.pickup_unavailable;
      return 'Pickup';
    case FULFILLMENT_METHODS.STORE:
      automationIdLabel = IDS.LABEL.stsLabel;
      automationIdStatus = IDS.UA.sts_unavailable;
      return 'Ship to Store';
    default:
      automationIdLabel = '';
      automationIdStatus = '';
      return null;
    }
  };

  return (
    <div className="">
      <TileHead title={getTitle(type)} isUnavailable automationId={automationIdLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" color="inactive">
          <div data-automation-id={automationIdStatus}>Unavailable</div>
        </Typography>

      </div>
    </div>

  );
};
NotAvailable.propTypes = {
  type: string
};
NotAvailable.defaultProps = {
  type: null
};

export { NotAvailable };
