import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { IDS } from '../../automationTestIds';
import { track } from '../../analytics';

export const DeliveryHeader = ({
  zipCode,
  showCheckAvailability,
  setShowCheckAvailability,
  isCartTiles,
  applianceCountInCart,
  isMajorAppliance,
}) => {

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
    track('click', {
      origin: 'delivery-options',
      eventName: 'check fulfillment availability',
      primaryCategory: 'button submission'
    });
  };

  const openZipDrawer = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.LIFE_CYCLE_EVENT_BUS.trigger(
      'cart.zip-code-update-click'
    );
  };

  const availabilityStencilButton = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      classNamecomponent="span"
      onClick={handleOnClick}
      data-testid="delivery-header-zipcode-link"
    >
      { zipCode }
    </Link>
  );
  const appCheckAvailabilityButton = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      classNamecomponent="span"
      color="primary"
      onClick={openZipDrawer}
      data-testid="delivery-header-zipcode-link"
    >
      { zipCode }
    </Link>
  );

  const applZipCode = (applianceCountInCart === 1) ? appCheckAvailabilityButton : zipCode;

  return (
    <div
      className="sui-flex sui-w-1/2 sui-pl-2 sui-mt-auto"
      data-automation-id={IDS.LABEL.deliveryToLabel}
    >
      <Typography variant="body-base" height="normal">
        {'Delivering to '}
        <span className="sui-cursor-pointer">{ isCartTiles
          && isMajorAppliance ? applZipCode : availabilityStencilButton }
        </span>
      </Typography>
    </div>
  );
};

DeliveryHeader.propTypes = {
  zipCode: PropTypes.string.isRequired,
  showCheckAvailability: PropTypes.bool.isRequired,
  setShowCheckAvailability: PropTypes.func.isRequired,
  isCartTiles: PropTypes.bool,
  applianceCountInCart: PropTypes.number,
  isMajorAppliance: PropTypes.bool.isRequired,
};

DeliveryHeader.defaultProps = {
  isCartTiles: false,
  applianceCountInCart: 0
};
