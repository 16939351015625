import React from 'react';
import {
  Skeleton, SkeletonLine
} from '@one-thd/sui-atomic-components';

export const DeliveryMessageUnderTilePlaceholder = () => {
  return (
    <div className="sui-row-span-3" data-testid="deliverymsg-divider-top__placeholder">
      <Skeleton
        data-component="DeliveryMessageUnderTilePlaceholder"
        component="span"
        grow
      >
        <SkeletonLine variant="multi" numberOfLines={2} />
      </Skeleton>
    </div>

  );
};

DeliveryMessageUnderTilePlaceholder.propTypes = {
};

DeliveryMessageUnderTilePlaceholder.defaultProps = {
  isMajorAppliances: false
};
