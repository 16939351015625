/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { number, object, bool, string } from 'prop-types';
import { useStore, useConfigService } from '@thd-nucleus/experience-context';
import { InfoFilled } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../../TileHead';
import { getDefaultStoreHours, showBopisDate } from '../../../../helper/timeZoneUtils';
import { formatInventory } from '../../../../helper/utils';
import { IDS } from '../../../../../automationTestIds';
import { NotAvailable } from '../../NotAvailable';

const ClearanceStore = ({
  quantity, pickupStoreHours, isLimitedQuantity, clearanceStoreName, isHideLimitedStockEnabled
}) => {
  let { storeHours } = useStore();
  const isHideLimitedStockClearanceEnabled = useConfigService('fs:isHideLimitedStockClearanceEnabled')
  || isHideLimitedStockEnabled;

  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const pickupStoreHoursWithTZ = { ...storeHours, ...pickupStoreHours };
  const formattedInventoryQty = formatInventory(quantity);

  if (isLimitedQuantity && isHideLimitedStockClearanceEnabled) {
    return <NotAvailable type="BOPIS" />;
  }

  return (
    <div>
      <TileHead isUnavailable title={isLimitedQuantity ? 'Limited Stock' : 'At Your Store'} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography isUnavailable variant="body-base" color="text-colors-inactive">
          <div
            className="sui-font-bold sui-min-h-[24px] sui-pb-1"
            data-testid="pickupTimeline"
          >
            {isLimitedQuantity ? 'On Clearance at' : showBopisDate(pickupStoreHoursWithTZ, true)}
          </div>
        </Typography>
        <div className="sui-min-h-[24px]">
          <Typography variant="body-base" color="text-colors-inactive">
            {isLimitedQuantity ? (
              <span data-testid="fulfillment-qty-row">
                {clearanceStoreName}
              </span>
            ) : (
              <span>
                <span
                  data-testid="fulfillment-qty-row"
                  data-automation-id={IDS.QTY.atYourStoreQty}
                >
                  <span className="sui-text-success">{formattedInventoryQty}</span>
                  &nbsp;in stock
                </span>
              </span>
            )}
            <span>&nbsp;
              <InfoFilled size="small" style={{ color: '#54B4AC', verticalAlign: 'text-bottom' }} />
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );

};

ClearanceStore.propTypes = {
  quantity: number,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  isLimitedQuantity: bool,
  clearanceStoreName: string,
  isHideLimitedStockEnabled: bool
};
ClearanceStore.defaultProps = {
  pickupStoreHours: null,
  quantity: 0,
  isLimitedQuantity: false,
  clearanceStoreName: null,
  isHideLimitedStockEnabled: false
};

export { ClearanceStore };