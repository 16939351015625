import React from 'react';
import {
  string,
  shape,
  number
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const LimitedStockByStoreNearby = ({ location }) => {
  return (
    <div className="">
      <TileHead title="Pickup Nearby" isUnavailable automationId={IDS.LABEL.pickupNearByLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" color="text-colors-inactive">
          Limited stock at {location?.storeName}
        </Typography>

      </div>
    </div>

  );
};

LimitedStockByStoreNearby.propTypes = {
  location: shape({
    storeName: string,
    distance: number
  }).isRequired
};

LimitedStockByStoreNearby.defaultProps = {};

export { LimitedStockByStoreNearby };
