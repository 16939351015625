import { useEffect } from 'react';
import { isQuantityLimitExceed } from '../components/helper/utils';

export const useHandleOnChange = ({
  onChange,
  getCurrentFulfillment,
  fulfillmentModels,
  availabilityType,
  selectedFulfillmentMethod,
  itemId,
  primaryStoreNum,
  isCartTiles,
  isBackorderedApplied
}) => {
  useEffect(() => {
    if (onChange && fulfillmentModels) {
      const currentFulfillment = getCurrentFulfillment(selectedFulfillmentMethod, fulfillmentModels);
      const currentFulfillmentMethod = currentFulfillment?.method || selectedFulfillmentMethod;
      const [storeObj = {}, shippingObj = {}] = fulfillmentModels;
      const isShowATC = !availabilityType?.discontinued
        && (shippingObj.enabled || storeObj.enabled);
      const disableATC = !shippingObj.enabled && storeObj.quantityLimitExceed;
      const isOutOfStockOnline = !!shippingObj?.outOfStock;
      const quantityLimitExceed = isQuantityLimitExceed({
        method: currentFulfillmentMethod,
        storeObj,
        shippingObj,
        expressObj: {}
      });
      const isPickupNearby = !!(storeObj?.isPickupNearby);
      const availableFulfillments = fulfillmentModels
        .filter((model) => model.enabled === true)
        .map((model) => (model.method));
      const isItemOOS = (storeObj.unavailable && shippingObj.unavailable)
        || (storeObj.outOfStock && shippingObj.outOfStock && ['Online', 'Shared'].includes(availabilityType?.type));

      onChange({
        method: currentFulfillmentMethod,
        isShowATC,
        disableATC,
        isOutOfStockOnline,
        quantityLimitExceed: isBackorderedApplied ? false : quantityLimitExceed,
        availableFulfillments,
        isPickupNearby,
        primaryStoreNum,
        isItemOOS
      });
    }
  }, [
    (!isCartTiles ? fulfillmentModels : null),
    itemId,
    // quantity,
    selectedFulfillmentMethod
  ]
  );
};
