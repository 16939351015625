import React, { useContext } from 'react';
import {
  node,
  number,
  bool,
  string,
  object
} from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';

const CheckNearByStore = (props) => {
  const { channel } = useContext(ExperienceContext);
  const {
    itemId,
    quantity,
    originalConfigId,
    lineItemId,
    children,
    isShipToHomeEligible,
    addOnsAssemblyAndMore,
    isCartTiles,
    storeIdFromCart,
    leadTime,
    hasCustomATC,
    storeNameLink
  } = props;
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    host = window.location.origin.match(/local/g)
      ? 'https://www.homedepot.com' : window.location.origin;
  }

  const { storeId } = useStore();

  const cartReqParams = {
    itemId,
    host,
    quantity,
    keyword: isCartTiles ? storeIdFromCart : storeId,
    channel,
    isShipToHomeEligible,
    alterBrowserHistory: true,
    addOnsAssemblyAndMore,
    leadTime,
    hasCustomATC
  };

  if (lineItemId) {
    cartReqParams.lineItemId = lineItemId;
  }
  if (originalConfigId) {
    cartReqParams.paintConfiguration = { configurationId: originalConfigId };
  }

  function triggerSelectStore(event) {
    event.stopPropagation();
    event.preventDefault();
    window.LIFE_CYCLE_EVENT_BUS.trigger('cart.select-store', {
      cartReqParams
    });
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      // eslint-disable-next-line react/jsx-no-bind
      onClick={triggerSelectStore}
      data-testid="check-nearby-stores-link"
      className={'sui-flex sui-inline-flex' + (storeNameLink ? ' sui-underline' : '')}
    >
      { children }
    </Link>
  );
};

CheckNearByStore.propTypes = {
  itemId: string.isRequired,
  children: node.isRequired,
  isShipToHomeEligible: bool,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: object,
  isCartTiles: bool,
  storeIdFromCart: string,
  lineItemId: string,
  quantity: number,
  leadTime: number,
  hasCustomATC: bool,
  originalConfigId: string,
  storeNameLink: bool
};

CheckNearByStore.defaultProps = {
  isShipToHomeEligible: false,
  addOnsAssemblyAndMore: null,
  storeIdFromCart: '',
  isCartTiles: false,
  lineItemId: '',
  quantity: 1,
  leadTime: 0,
  hasCustomATC: false,
  originalConfigId: null,
  storeNameLink: false
};

export { CheckNearByStore };
