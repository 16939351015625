/* eslint-disable max-len */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  getCurrentSelectedFulfillmentDetails, formatDate, showDateOrDateRange,
  IsPickupUnavailable, showTodayOrTomorrow
} from '../../helper/utils';

import { showBopisDate } from '../../helper/timeZoneUtils';

export const ListViewFulfillmentDisplay = ({ fulfillment, item }) => {
  const isAppliance = item?.product?.identifiers?.productType === 'Appliance';
  const isGiftCard = item?.product?.identifiers?.productType === 'GIFT_CARD';
  const {
    isPickup,
    shipToStore,
    startDate,
    endDate,
    deliveryTimeline,
    storeHours,
    displayText,
    displayFulfillment,
    availableInventory,
    inventoryText,
    backorderedItem,
    location,
    deliveryStartDate,
    deliveryEndDate,
    estDeliveryDate,
    applDeliveryDate
  } = getCurrentSelectedFulfillmentDetails(fulfillment);

  const pickupUnavailable = IsPickupUnavailable({ fulfillment });
  const bopisDate = isPickup && !shipToStore ? showBopisDate(storeHours) : '';
  const formattedDeliveryStartDate = deliveryStartDate ? formatDate(deliveryStartDate, false) : '';
  const formattedStartDate = formatDate(startDate, false);
  const formattedEndDate = formatDate(endDate, false);
  let bossDateRange = '';
  let bossDateToday = '';
  let deliveryDate = '';
  let formattedDeliveryEndDate = '';
  if (shipToStore) {
    bossDateToday = showTodayOrTomorrow(endDate, true, deliveryTimeline, formattedEndDate, true);
    bossDateRange = showDateOrDateRange({ formattedStartDate, formattedEndDate });
  }
  if (deliveryEndDate) {
    formattedDeliveryEndDate = formatDate(deliveryEndDate, false);
    deliveryDate = showTodayOrTomorrow(deliveryEndDate, true, deliveryTimeline, formattedDeliveryEndDate, true);
  }

  const getFfmDate = () => {
    if (isPickup) {
      if (!shipToStore) {
        return bopisDate;
      }
    }
    if (formattedDeliveryStartDate === formattedDeliveryEndDate) {
      return deliveryDate;
    }
    return estDeliveryDate;
  };

  return (
    <>
      {(isAppliance && !isPickup) ? (
        <>
          <Typography>
            <span className="u__bold">{displayFulfillment}</span>&nbsp;to&nbsp;{location} Earliest
            delivery date {applDeliveryDate}
            { pickupUnavailable
            && (
              <>
              &nbsp;|&nbsp;Pickup Unavailable
              </>
            )}
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            <span className="u__bold">{displayFulfillment}</span>
            <span>
              {shipToStore && (
                <>&nbsp;{formattedStartDate === formattedEndDate ? bossDateToday : bossDateRange}</>
              )}
              {<>&nbsp;{getFfmDate()}</>}
              {displayText && !isGiftCard && (displayFulfillment || deliveryDate || estDeliveryDate) && (
                <>
                  &nbsp;|&nbsp;<span className="sui-text-success">{displayText}</span>
                </>
              )}
            </span>
          </Typography>
          <Typography>
            {backorderedItem || (availableInventory && <>{backorderedItem || inventoryText}</>)}
          </Typography>
        </>
      )}
    </>
  );
};

ListViewFulfillmentDisplay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fulfillment: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object
};

ListViewFulfillmentDisplay.defaultProps = {
  item: {},
  fulfillment: {}
};
