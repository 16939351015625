/* eslint-disable max-len */
import React from 'react';
import { NotAvailable } from './partials/NotAvailable';
import { FULFILLMENT_METHODS } from '../constants';
import { ApplianceShipping } from './partials/shipping/ApplianceShipping';
import { ApplianceShippingDetails } from './partials/shipping/ApplianceShipping_Details';
import {
  isApplianceDeliveryBackordered,
  isApplianceDeliveryOutOfStock,
  isApplianceDeliveryUnavailable,
  isApplianceDeliveryError
} from '../helper/utils';
import { meetsApplianceDeliveryThreshold } from '../../pod/helpers/pod-fulfillment-utils';

export const getApplianceDeliveryTemplate = ({
  applianceDeliveryData,
  pricing,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  applianceDeliveryThreshold,
  isExchangeCustomer,
  isCartTiles,
  hideShippingThreshold
}) => {

  const isFreeShip = meetsApplianceDeliveryThreshold(pricing, applianceDeliveryThreshold);
  const isMinimumFreeShipPrice = !isCartTiles ? isFreeShip : applianceDeliveryData?.hasFreeShipping;
  const isApplianceBackordered = isApplianceDeliveryBackordered(applianceDeliveryData);
  const isApplianceOutOfStock = isApplianceDeliveryOutOfStock(applianceDeliveryData);
  const isApplianceUnavailable = isApplianceDeliveryUnavailable(applianceDeliveryData);
  const isApplianceError = isApplianceDeliveryError(applianceDeliveryData);

  const applianceFlatChargeApplies = enableApplianceDeliveryCharge && !isExchangeCustomer;
  const forceFreeShippingForExchange = isExchangeCustomer && enableFreeDeliveryForExchange;

  let hasFreeShipping = isMinimumFreeShipPrice;
  // FEATURE SWITCH OVERRIDE
  if (applianceFlatChargeApplies) hasFreeShipping = false;
  // FEATURE SWITCH OVERRIDE
  else if (forceFreeShippingForExchange) hasFreeShipping = true;

  if (applianceDeliveryData) {
    if (isApplianceUnavailable || isApplianceError) {
      return {
        method: FULFILLMENT_METHODS.APPLIANCE,
        enabled: false,
        template: <NotAvailable type={FULFILLMENT_METHODS.APPLIANCE} />
      };
    }

    if (isApplianceOutOfStock) {
      return {
        method: FULFILLMENT_METHODS.APPLIANCE,
        enabled: false,
        unavailable: true, // we want to show the OOS msg and not the email notifyme
        template: <NotAvailable type={FULFILLMENT_METHODS.APPLIANCE} />
      };
    }
    return {
      method: FULFILLMENT_METHODS.APPLIANCE,
      enabled: true,
      template: <ApplianceShipping
        deliveryStartDate={applianceDeliveryData?.earliestAvailabilityDate}
        hasFreeShipping={hasFreeShipping}
        hideShippingThreshold={hideShippingThreshold}
      />,
      subTemplate: <ApplianceShippingDetails />
    };
  }

  return {
    method: FULFILLMENT_METHODS.APPLIANCE,
    enabled: false,
    template: <NotAvailable type={FULFILLMENT_METHODS.APPLIANCE} />
  };
};
