/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import {
  string,
  shape,
  arrayOf,
  number,
  bool
} from 'prop-types';
import { Alert } from '@one-thd/sui-atomic-components';

const InStockNearByStoreDetails = ({
  fulfillment,
  quantity,
  pricing,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores
}) => {
  const nearByStoreLocation = (fulfillment.locations || []).find((location) => !location.isAnchor);
  const isBopisThresholdStore = bopisMinimumThresholdStores?.split(',').indexOf(nearByStoreLocation?.locationId) !== -1;
  const showMinimumThresholdMessage = (quantity * pricing?.value) < bopisMinimumThreshold;
  return nearByStoreLocation?.inventory?.isInStock && (
    <div>
      {showMinimumThresholdMessage && isBopisThresholdStore && (
        <Alert status="warning">
          <span>{`There&apos;s a ${bopisMinimumThreshold} minimum purchase for pickup.`}</span>
        </Alert>
      )}
    </div>
  );
};

InStockNearByStoreDetails.propTypes = {
  quantity: number,
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  pricing: shape({
    value: number
  }).isRequired,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string
};

InStockNearByStoreDetails.defaultProps = {
  quantity: 1,
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null
};

export { InStockNearByStoreDetails };
