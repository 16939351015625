/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { bool } from 'prop-types';
import { Skeleton, SkeletonLine } from '@one-thd/sui-atomic-components';

const FulfillmentPlaceholderStencil = ({ isMajorAppliances }) => {

  return (
    <div
      className="sui-bg-subtle"
      data-testid="buybox-placeholder-test-id"
      data-component="BuyboxLoaderTitle"
      aria-label="FulfillmentPlaceholderStencil"
    >

      <div className="sui-grid sui-grid-cols-3 sui-gap-4">
        <div className="sui-max-w-[278px]">
          <Skeleton
            data-component="FulfillmentPlaceholder"
            component="span"
            grow
          >
            <SkeletonLine variant="multi" numberOfLines={3} />
          </Skeleton>
        </div>
        <div className="sui-max-w-[278px]">
          <Skeleton
            data-component="FulfillmentPlaceholder"
            component="span"
            grow
          >
            <SkeletonLine variant="multi" numberOfLines={3} />
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

FulfillmentPlaceholderStencil.propTypes = {
  isMajorAppliances: bool
};

FulfillmentPlaceholderStencil.defaultProps = {
  isMajorAppliances: false
};

export { FulfillmentPlaceholderStencil };