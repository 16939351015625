/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import { BOSSOutOfStock } from './partials/store/BOSSOutOfStock';
import { BopisNotFulfillableOOS } from './partials/store/StoreOnlyTile/BopisNotFulfillableOOS';
import { ClearanceStore } from './partials/store/StoreOnlyTile/ClearanceStore';
import { DefaultBoSS } from './partials/store/DefaultBoSS';
import { DefaultShipping } from './partials/shipping/DefaultShipping';
import { DefaultShippingDetails } from './partials/shipping/DefaultShipping_Details';
import { ExpressDeliveryDetails } from './partials/shipping/ExpressDelivery_Details';
import { InStockNearByStore } from './partials/store/InStockNearByStore';
import { InStockNearByStoreDetails } from './partials/store/InStockNearByStoreDetails';
import { LimitedStockByStoreNearby } from './partials/store/LimitedStockByStoreNearby';
import { NotAvailable } from './partials/NotAvailable';
import { OffshoreBossUnavailable } from './partials/store/OffshoreBossUnavailable';
import { OffshoreSthUnavailable } from './partials/shipping/OffshoreSthUnavailable';
import { StoreOnly } from './partials/store/StoreOnlyTile/StoreOnly';
import { DefaultBOPIS } from './partials/store/DefaultBOPIS';
import { DefaultBOPISDetails } from './partials/store/DefaultBOPISDetails';
import { LimitedStockByStore } from './partials/store/LimitedStockByStore';

const SUB_TEMPLATES = {
  DELIVERY: { DefaultShippingDetails, ExpressDeliveryDetails },
  PICKUP: { DefaultBOPISDetails, InStockNearByStoreDetails }
};
const TEMPLATES = {
  DELIVERY: { DefaultShipping, OffshoreSthUnavailable },
  PICKUP: {
    BopisNotFulfillableOOS,
    BOSSOutOfStock,
    ClearanceStore,
    DefaultBOPIS,
    DefaultBoSS,
    InStockNearByStore,
    LimitedStockByStore,
    LimitedStockByStoreNearby,
    OffshoreBossUnavailable,
    StoreOnly
  },
  SHARED: { NotAvailable }
};

export const getTemplate = ({
  ffm,
  subTemplateName,
  subTemplateFields,
  templateName,
  templateFields
}) => {
  let subTemplate;
  if (subTemplateName) {
    const SubTemplateToUse = SUB_TEMPLATES[ffm][subTemplateName];
    subTemplate = <SubTemplateToUse {...subTemplateFields} />;
  }

  const TemplateToUse = TEMPLATES[ffm][templateName];
  const template = <TemplateToUse {...templateFields} />;

  return {
    subTemplate,
    template
  };
};
