/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Tile, TileContent } from '@one-thd/sui-atomic-components';
import { ConfirmedFilled } from '@one-thd/sui-icons';

export const DeliveryTile = ({
  getWrapperClasses,
  fulfillmentObj,
  currentFulfillmentMethod,
  setSelectedFulfillmentMethod
}) => {

  const isDeliverySelected = fulfillmentObj.method === currentFulfillmentMethod && fulfillmentObj.enabled;
  return (
    <Tile
      value="deliveryTile"
      aria-label="deliveryTile"
      onClick={() => {
        if ((fulfillmentObj.method === currentFulfillmentMethod) || !fulfillmentObj.enabled) return;
        setSelectedFulfillmentMethod(fulfillmentObj.method);
      }}
      selected={isDeliverySelected}
      unavailable={!fulfillmentObj.enabled}
      disabled={!fulfillmentObj.enabled}
    >
      <TileContent
        orientation="vertical"
        alignItems="start"
        grow
      >
        <div className="sui-flex sui-flex-wrap">
          {fulfillmentObj.template}
          {isDeliverySelected
              && (
                <div className="sui-absolute sui-top-0 sui-right-0 sui-pt-2 sui-pr-2 sui-text-brand">
                  <ConfirmedFilled size="small" />
                </div>
              )}
        </div>
      </TileContent>
    </Tile>

  );

};

DeliveryTile.propTypes = {
  getWrapperClasses: PropTypes.func.isRequired,
  fulfillmentObj: PropTypes.object.isRequired,
  currentFulfillmentMethod: PropTypes.string.isRequired,
  setSelectedFulfillmentMethod: PropTypes.func.isRequired
};

DeliveryTile.defaultProps = {};
