/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import {
  arrayOf, bool, number, shape, string, object
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { Typography } from '@one-thd/sui-atomic-components';
import { InfoFilled } from '@one-thd/sui-icons';
import { TileHead } from '../../TileHead';
import { formatInventory } from '../../../../helper/utils';
import { getDefaultStoreHours, showBopisDate } from '../../../../helper/timeZoneUtils';
import { IDS } from '../../../../../automationTestIds';

const StoreOnly = ({
  bopisFulfillment, itemId, isShipToHomeEligible, isAppliance, isCartTiles, pickupStoreHours
}) => {
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };
  const localStoreLocation = (bopisFulfillment?.locations || []).find(
    (location) => location.isAnchor
  );
  const quantity = localStoreLocation?.inventory?.quantity;
  const formattedInventoryQty = formatInventory(quantity);

  return (
    <div>
      <div className="sui-flex sui-items-center">
        <TileHead title="At Your Store" automationId={IDS.LABEL.atYourStoreLabel} />
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <div className="sui-pt-[16px]" data-testid="AtYourStoreInfoIcon">&nbsp;
          <InfoFilled size="small" style={{ color: '#54B4AC', verticalAlign: 'text-bottom' }} />
        </div>
      </div>
      <div data-testid="ffm-details-row" className="sui-pt-2">
        {quantity > 0 && (
          <>
            <Typography variant="body-base" color="text-colors-inactive">
              <div
                className="sui-font-bold sui-min-h-[24px] sui-pb-1"
                data-testid="pickupTimeline"
                data-automation-id={IDS.ETA.atYourStoreETA}
              >
                {showBopisDate(pickupStoreHoursWithTZ)}
              </div>
            </Typography>
            <Typography variant="body-base">
              <div
                className="sui-min-h-[24px]"
                data-testid="fulfillment-qty-row"
                data-automation-id={IDS.QTY.atYourStoreQty}
              >
                <span className="sui-text-success">{formattedInventoryQty}</span>
                    &nbsp;in stock
                {quantity === 0 && <span>Visit your store to check availability</span>}
              </div>
            </Typography>
          </>
        )}
      </div>
    </div>
  );

};
StoreOnly.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  isAppliance: bool,
  bopisFulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }),
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object
};
StoreOnly.defaultProps = {
  isShipToHomeEligible: false,
  isAppliance: false,
  bopisFulfillment: null,
  isCartTiles: false,
  pickupStoreHours: null
};

export { StoreOnly };
