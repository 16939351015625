import { FULFILLMENT_METHODS } from '../constants';
import {
  getNearByStore,
  getOnlineLocation,
  isCovidAlertEnabled,
  isMajorApplianceProductType,
  getPickupStoreTimeZone,
  isItemOnClearance,
  getLocalStore
} from '../helper/utils';
import {
  handleBopis,
  handleBoss,
  handleDefaultPickup,
  handleFallbackMode,
  handleFfmUnavailableOrClearance,
  handleNearBy,
  handleNoBopisForAppliance,
  handleNoFfmOrBossExcluded,
  handleNoPickupAndBossExcluded
} from './pickupTemplateGeneratorHelper';

const isQuantityLimitExceed = ({ inventoryQuantity, selectedQuantity }) => {
  return inventoryQuantity > 0 && inventoryQuantity < selectedQuantity;
};

export const getPickupTemplate = ({
  fulfillment,
  type,
  productType,
  channel,
  clearancePricing,
  covidAlert,
  quantity,
  originalConfigId,
  itemId,
  pricing,
  thresholds,
  isCartTiles,
  addOnsAssemblyAndMore,
  fallbackMode,
  isSpecialOrder,
  configuratorHideQuantity,
  disableBOSS,
  disableSTH,
  disablePickupNearBy
}) => {
  const {
    bopisMinimumThreshold = null,
    bopisMinimumThresholdStores = null,
    bopisHolidayTiming = null
  } = thresholds;
  // TODO: rename type to availabilityType for clarity
  const deliveryService = !disableSTH
    ? fulfillment?.fulfillmentOptions?.find((option) => option.type === 'delivery')
    : null;
  const pickupService = fulfillment?.fulfillmentOptions?.find((option) => option.type === 'pickup');
  const shippingFulfillment = deliveryService?.services?.find((service) => service.type === 'sth');
  const bossFulfillment = !disableBOSS
    ? pickupService?.services?.find((service) => service.type === 'boss')
    : null;
  const bopisFulfillment = pickupService?.services?.find((service) => service.type === 'bopis');

  let method = bopisFulfillment ? FULFILLMENT_METHODS.BOPIS : FULFILLMENT_METHODS.STORE;
  const nearByStoreLocation = getNearByStore(bopisFulfillment);

  const pickupStoreHours = isCartTiles
    ? bopisFulfillment?.nearbyStores?.storeHoursModel || bopisFulfillment?.storeHours
    : { storeTimeZone: getPickupStoreTimeZone(fulfillment) };

  const bossLocation = getOnlineLocation(bossFulfillment);
  const covidEnabled = isCovidAlertEnabled(covidAlert, bopisHolidayTiming);
  const isAppliance = isMajorApplianceProductType(productType);
  const isClearanceInStockOrLQTY = !!(isItemOnClearance(fulfillment) && clearancePricing);
  const localStoreLocation = getLocalStore(bopisFulfillment);

  const nearByQuantityLimitExceed = isQuantityLimitExceed({
    inventoryQuantity: nearByStoreLocation?.inventory?.quantity,
    selectedQuantity: quantity
  });
  const bossQuantityLimitExceed = isQuantityLimitExceed({
    inventoryQuantity: bossLocation?.inventory?.quantity,
    selectedQuantity: quantity
  });
  const localStoreQuantityLimitExceed = isQuantityLimitExceed({
    inventoryQuantity: localStoreLocation?.inventory?.quantity,
    selectedQuantity: quantity
  });

  return (
    // Fallback mode
    handleFallbackMode({
      bossFulfillment,
      configuratorHideQuantity,
      fallbackMode,
      isCartTiles,
      isSpecialOrder
    })
    // No fulfillment options OR BOSS excluded state
    || handleNoFfmOrBossExcluded({ fulfillment, method, itemId, shippingFulfillment })
    //  No pickup available AND BOSS excluded state
    || handleNoPickupAndBossExcluded({
      fulfillment,
      bossFulfillment,
      bopisFulfillment,
      method,
      itemId,
      shippingFulfillment,
      addOnsAssemblyAndMore
    })
    // Major appliances not available for pickup
    || handleNoBopisForAppliance({ fulfillment, itemId, productType })
    // No fulfillment or pickup service, or a clearance item
    || handleFfmUnavailableOrClearance({
      bopisFulfillment,
      bopisMinimumThreshold,
      bopisMinimumThresholdStores,
      bossFulfillment,
      disablePickupNearBy,
      fulfillment,
      isCartTiles,
      isClearanceInStockOrLQTY,
      itemId,
      method,
      nearByQuantityLimitExceed,
      nearByStoreLocation,
      pickupService,
      pickupStoreHours,
      pricing,
      quantity,
      type
    })
    // Pickup available at nearby location
    || handleNearBy({
      bopisFulfillment,
      bopisMinimumThreshold,
      bopisMinimumThresholdStores,
      bossFulfillment,
      bossQuantityLimitExceed,
      configuratorHideQuantity,
      covidEnabled,
      disablePickupNearBy,
      isCartTiles,
      isClearanceInStockOrLQTY,
      isSpecialOrder,
      itemId,
      method,
      nearByQuantityLimitExceed,
      nearByStoreLocation,
      pickupStoreHours,
      pricing,
      quantity
    })
    // Various BOPIS pickup scenarios
    || handleBopis({
      addOnsAssemblyAndMore,
      bopisFulfillment,
      bopisMinimumThreshold,
      bopisMinimumThresholdStores,
      bossFulfillment,
      bossQuantityLimitExceed,
      channel,
      configuratorHideQuantity,
      covidEnabled,
      fulfillment,
      isAppliance,
      isCartTiles,
      isClearanceInStockOrLQTY,
      isSpecialOrder,
      itemId,
      localStoreLocation,
      localStoreQuantityLimitExceed,
      method,
      nearByStoreLocation,
      originalConfigId,
      pickupService,
      pickupStoreHours,
      pricing,
      quantity,
      shippingFulfillment,
      type
    })
    // Various BOSS pickup scenarios
    || handleBoss({
      bossFulfillment,
      bossQuantityLimitExceed,
      configuratorHideQuantity,
      fulfillment,
      isCartTiles,
      isSpecialOrder,
      itemId,
      method,
      quantity,
      shippingFulfillment,
      type
    })
    // Default scenario
    || handleDefaultPickup({ itemId, type })
  );
};
