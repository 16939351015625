import React from 'react';
import { bool } from 'prop-types';
import { FulfillmentPlaceholderStencil } from './FulfillmentPlaceholderStencil';

const FulfillmentPlaceholder = ({ isMajorAppliances }) => {

  return <FulfillmentPlaceholderStencil isMajorAppliances={isMajorAppliances} />;

};

FulfillmentPlaceholder.propTypes = {
  isMajorAppliances: bool
};

FulfillmentPlaceholder.defaultProps = {
  isMajorAppliances: false
};

export { FulfillmentPlaceholder };
