/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { getPickupTemplate } from './PickupTemplateGenerator';
import { getDeliveryTemplate } from './DeliveryTemplateGenerator';
import { getApplianceDeliveryTemplate } from './ApplianceDeliveryTemplateGenerator';
import {
  getExcludedStateName,
  getLiveGoodsStock,
  isApplianceDeliveryError,
  isApplianceDeliveryOutOfStock, isApplianceDeliveryUnavailable,
  isExchangeItemUnavailable
} from '../helper/utils';
import { Discontinued } from '../shared/Discontinued/Discontinued';
import { DualPath } from '../shared/DualPath/DualPath';
import { ExcludedShipState } from './ExcludedShipState/ExcludedShipState';
import { ItemUnavailable } from '../shared/ItemUnavailable/ItemUnavailable';
import { LiveGoods } from '../shared/LiveGoods/LiveGoods';
import { NotifyMe } from '../shared/NotifyMe/NotifyMe';

export const getFulfillmentTileModels = ({
  fulfillment,
  type,
  clearancePricing,
  covidAlert,
  quantity,
  originalConfigId,
  zipCode,
  storeId,
  itemId,
  storeName,
  channel,
  pricing,
  thresholds,
  setShowCheckAvailability,
  currentDeliveryFfm,
  shippingData,
  preferSecondaryFFM = false,
  productType,
  isCartTiles,
  addOnsAssemblyAndMore,
  fallbackMode,
  isSpecialOrder,
  configuratorHideQuantity,
  isConfigurableBlinds,
  disableSTH,
  disableBODFS,
  disableBOSS,
  disablePickupNearBy,
  disableTimer,
  hideShippingThreshold
}) => {
  const shippingTemplate = getDeliveryTemplate({
    fulfillment,
    type,
    zipCode,
    storeId,
    quantity,
    itemId,
    setShowCheckAvailability,
    covidAlert,
    pricing,
    currentDeliveryFfm,
    shippingData,
    preferSecondaryFFM,
    isCartTiles,
    fallbackMode,
    isSpecialOrder,
    configuratorHideQuantity,
    isConfigurableBlinds,
    disableSTH,
    disableBODFS,
    disableTimer,
    storeName,
    hideShippingThreshold,
    originalConfigId
  });

  const storeTemplate = getPickupTemplate({
    channel,
    itemId,
    covidAlert,
    fulfillment,
    type,
    quantity,
    originalConfigId,
    storeName,
    pricing,
    thresholds,
    productType,
    isCartTiles,
    addOnsAssemblyAndMore,
    fallbackMode,
    isSpecialOrder,
    configuratorHideQuantity,
    disableBOSS,
    disableSTH,
    disablePickupNearBy,
    clearancePricing
  });

  return [storeTemplate, shippingTemplate];
};

export const getMajorApplianceTileModels = ({
  applianceDeliveryData,
  fulfillment,
  type,
  covidAlert,
  quantity,
  itemId,
  storeName,
  channel,
  pricing,
  thresholds,
  productType,
  addOnsAssemblyAndMore,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  applianceDeliveryThreshold,
  isExchangeCustomer,
  isCartTiles,
  hideShippingThreshold
}) => {
  const shippingTemplate = getApplianceDeliveryTemplate({
    applianceDeliveryData,
    pricing,
    enableApplianceDeliveryCharge,
    enableFreeDeliveryForExchange,
    applianceDeliveryThreshold,
    isExchangeCustomer,
    isCartTiles,
    hideShippingThreshold
  });

  const storeTemplate = getPickupTemplate({
    channel,
    itemId,
    covidAlert,
    fulfillment,
    type,
    quantity,
    storeName,
    pricing,
    thresholds,
    productType,
    addOnsAssemblyAndMore
  });

  return [storeTemplate, shippingTemplate];
};

export const getEdgeCase = ({
  addOnsAssemblyAndMore,
  applianceDeliveryData,
  availabilityType,
  category,
  checkAvailabilityComponent,
  dualPath,
  fulfillment,
  isExchangeCustomer,
  isMajorAppliance,
  itemId,
  originalConfigId,
  product,
  setShowCheckAvailability,
  shippingObj,
  showCheckAvailability,
  storeObj,
  storeName,
}) => {
  // If not isMajorAppliance & user is an exchange customer & fulfillmentOptions are empty or null,
  // then display a common message for all different items
  if (isExchangeCustomer && !isMajorAppliance
    && isExchangeItemUnavailable(availabilityType, fulfillment, dualPath, product)
  ) {
    return <ItemUnavailable />;
  }

  if (isExchangeCustomer && isMajorAppliance
    && (isApplianceDeliveryUnavailable(applianceDeliveryData)
      || isApplianceDeliveryError(applianceDeliveryData)
      || isApplianceDeliveryOutOfStock(applianceDeliveryData))
  ) {
    return (
      <div className="fulfillment-tiles">
        <div className="fulfillment__fullcontent-alerts">
          <ItemUnavailable />
        </div>
      </div>
    );
  }

  if (storeObj.excluded && shippingObj.excluded) {
    return (
      <ExcludedShipState
        stateText={getExcludedStateName(fulfillment?.sthExcludedShipState)}
        itemId={itemId}
        originalConfigId={originalConfigId}
        checkAvailabilityComponent={checkAvailabilityComponent}
        showCheckAvailability={showCheckAvailability}
        setShowCheckAvailability={setShowCheckAvailability}
        addOnsAssemblyAndMore={addOnsAssemblyAndMore}
      />
    );
  }

  if (availabilityType?.discontinued) {
    return <Discontinued />;
  }

  if (getLiveGoodsStock(fulfillment) === 0) {
    return (
      <LiveGoods storeName={storeName} seasonStatusEligible={fulfillment?.seasonStatusEligible} />
    );
  }

  if (dualPath) {
    return <DualPath category={category} />;
  }

  if (
    (storeObj.unavailable && shippingObj.unavailable)
    || (storeObj.outOfStock
      && shippingObj.outOfStock
      && ['Online', 'Shared'].includes(availabilityType.type))
  ) {
    return (
      <>
        {checkAvailabilityComponent}
        <NotifyMe
          isMajorAppliance={isMajorAppliance}
          showCheckAvailability={showCheckAvailability}
          setShowCheckAvailability={setShowCheckAvailability}
          itemId={itemId}
          store={storeObj}
          shipping={shippingObj}
          skipEmailOptIn={availabilityType?.type === 'Store Only'}
        />
      </>
    );
  }
  return null;
};

export const shouldLoadPlaceholder = ({
  applianceDeliveryData,
  fulfillmentModels,
  isCartTiles,
  isConsumerApp,
  isMajorAppliance,
  isNativeDeliveryZipEventTriggered
}) => {
  return (
    !fulfillmentModels
    || (isMajorAppliance && !applianceDeliveryData)
    || (isConsumerApp && !isNativeDeliveryZipEventTriggered && !isCartTiles)
  );
};
