import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const OffshoreSthUnavailable = ({ stateName }) => {
  return (
    <div className="">
      <TileHead title="Delivery" isUnavailable automationId={IDS.LABEL.sthLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" color="inactive">
          <span data-automation-id={IDS.UA.sth_unavailable}>Unavailable in {stateName}</span>
        </Typography>

      </div>
    </div>
  );
};

OffshoreSthUnavailable.propTypes = {
  stateName: string
};

OffshoreSthUnavailable.defaultProps = {
  stateName: ''
};

export { OffshoreSthUnavailable };
