import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@one-thd/sui-atomic-components';

export const RedDangerAlert = ({
  errorMessage,
  boldSorry
}) => {
  if (!errorMessage) { return; }

  const boldSorryMessage = boldSorry
    ? (<span className="sui-font-bold">{'We\'re sorry. '}</span>)
    : null;

  // eslint-disable-next-line consistent-return
  return (
    <div className="sui-pt-2">
      <Alert status="error"><span>{boldSorryMessage}{errorMessage}</span></Alert>
    </div>
  );
};

RedDangerAlert.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  boldSorry: PropTypes.bool
};

RedDangerAlert.defaultProps = {
  boldSorry: false
};
