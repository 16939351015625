import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const BOSSOutOfStock = () => {
  return (

    <div className="">
      <TileHead title="Ship to Store" isUnavailable automationId={IDS.LABEL.stsLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" color="inactive">
          Out of stock
        </Typography>

      </div>
    </div>
  );
};

BOSSOutOfStock.propTypes = {
};

BOSSOutOfStock.defaultProps = {
};

export { BOSSOutOfStock };
