import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  number, string, func, bool, object, shape
} from 'prop-types';
import { CheckAvailability } from '@thd-olt-component-react/check-availability';
import { useConfigService } from '@thd-nucleus/experience-context';
import { FulfillmentTiles } from './tiles/FulfillmentTiles';
import { dataModel } from './dataModel';
import { setBopisCutOff } from './helper/timeZoneUtils';

const Fulfillment = (props) => {
  const {
    itemId,
    lineItemId,
    storeId,
    deliveryZip,
    quantity,
    originalConfigId,
    covidAlert,
    dualPath,
    category,
    onChange,
    onDeliveryZipCodeChange,
    bodfsMinimumThreshold,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming,
    currentSelectedFulfillment,
    directData,
    applianceDirectData,
    isBuyBoxLite,
    isCartTiles,
    localizerStoreDetails,
    quantityExceeded = false,
    addOnsAssemblyAndMore,
    bopisCutOffBeforeStoreClose,
    cartAction,
    disableSTH,
    disableBODFS,
    disableBOSS,
    openApplianceCheckAvailability,
    disablePickupNearBy,
    configurableProductDetails,
    leadTime,
    hasCustomATC,
    hideShippingThreshold
  } = props;
  const bopisHoursCutOffBeforeStoreClose = useConfigService('bopisCutOffBeforeStoreClose') || '3';
  const bopisCutOffTime = isCartTiles ? bopisCutOffBeforeStoreClose : bopisHoursCutOffBeforeStoreClose;
  setBopisCutOff(bopisCutOffTime);
  return (
    <>
      <FulfillmentTiles
        itemId={itemId}
        lineItemId={lineItemId}
        storeId={storeId}
        deliveryZip={deliveryZip}
        quantity={quantity}
        originalConfigId={originalConfigId}
        covidAlert={covidAlert}
        dualPath={dualPath}
        category={category}
        onChange={onChange}
        onDeliveryZipCodeChange={onDeliveryZipCodeChange}
        thresholds={{
          bodfsMinimumThreshold,
          bopisMinimumThreshold,
          bopisMinimumThresholdStores,
          bopisHolidayTiming
        }}
        currentSelectedFulfillment={currentSelectedFulfillment}
        directData={directData}
        applianceDirectData={applianceDirectData}
        isBuyBoxLite={isBuyBoxLite}
        isCartTiles={isCartTiles}
        localizerStoreDetails={localizerStoreDetails}
        quantityExceeded={quantityExceeded}
        addOnsAssemblyAndMore={addOnsAssemblyAndMore}
        cartAction={cartAction}
        disableSTH={disableSTH}
        disableBODFS={disableBODFS}
        disableBOSS={disableBOSS}
        openApplianceCheckAvailability={openApplianceCheckAvailability}
        disablePickupNearBy={disablePickupNearBy}
        leadTime={leadTime}
        hasCustomATC={hasCustomATC}
        configurableProductDetails={configurableProductDetails}
        hideShippingThreshold={hideShippingThreshold}
      />
    </>
  );
};

Fulfillment.displayName = 'Fulfillment';

Fulfillment.propTypes = {
  /** An Item that has product highlights */
  itemId: string.isRequired,
  lineItemId: string,
  storeId: string,
  deliveryZip: string,
  covidAlert: bool,
  quantity: number,
  originalConfigId: string,
  onChange: func,
  dualPath: bool,
  onDeliveryZipCodeChange: func,
  bodfsMinimumThreshold: number,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string,
  bopisHolidayTiming: number,
  currentSelectedFulfillment: string,
  // eslint-disable-next-line react/forbid-prop-types
  directData: object,
  // eslint-disable-next-line react/forbid-prop-types
  applianceDirectData: object,
  isBuyBoxLite: bool,
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  localizerStoreDetails: object,
  quantityExceeded: bool,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: object,
  bopisCutOffBeforeStoreClose: number,
  cartAction: string,
  category: string,
  disableSTH: bool,
  disableBODFS: bool,
  disableBOSS: bool,
  openApplianceCheckAvailability: func,
  disablePickupNearBy: bool,
  leadTime: number,
  hasCustomATC: bool,
  configurableProductDetails: shape({
    isSpecialOrder: bool,
    configuratorHideQuantity: bool,
    isConfigurableBlinds: bool
  }),
  hideShippingThreshold: bool
};

Fulfillment.defaultProps = {
  quantity: 1,
  originalConfigId: null,
  storeId: null,
  deliveryZip: '',
  covidAlert: false,
  onChange: () => { },
  onDeliveryZipCodeChange: () => { },
  dualPath: false,
  bodfsMinimumThreshold: null,
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null,
  bopisHolidayTiming: null,
  currentSelectedFulfillment: '',
  directData: null,
  applianceDirectData: null,
  isBuyBoxLite: false,
  isCartTiles: false,
  localizerStoreDetails: {},
  quantityExceeded: false,
  addOnsAssemblyAndMore: null,
  bopisCutOffBeforeStoreClose: 3,
  cartAction: '',
  category: '',
  lineItemId: '',
  disableSTH: false,
  disableBODFS: false,
  disableBOSS: false,
  openApplianceCheckAvailability: () => {},
  disablePickupNearBy: false,
  leadTime: 0,
  hasCustomATC: null,
  configurableProductDetails: {
    isSpecialOrder: false,
    configuratorHideQuantity: false,
    isConfigurableBlinds: false
  },
  hideShippingThreshold: false
};

// ['identifiers', 'info', 'pricing', 'availabilityType', 'fulfillment']
Fulfillment.dataModel = extend(
  CheckAvailability,
  dataModel
);

export { Fulfillment };
