/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { Typography, Link } from '@one-thd/sui-atomic-components';

const ItemUnavailable = () => {
  return (
    <div
      data-component="FulfillmentUnavailable"
      data-testid="fulfillment__unavailable"
    >
      <Typography variant="body-base" weight="bold">This item is unavailable</Typography>
    </div>
  );
};

export { ItemUnavailable };
