/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import {
  shape, bool, number, object, string
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { formatDate, formatInventory, showTodayOrTomorrow } from '../../../helper/utils';
import { getDefaultStoreHours, showBopisDate } from '../../../helper/timeZoneUtils';
import { IDS } from '../../../../automationTestIds';

const DefaultBOPIS = ({
  localStoreLocation, pickupStoreHours, isCartTiles, fallbackMode,
  configuratorHideQuantity, deliveryTimeline
}) => {
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const isInStock = localStoreLocation?.inventory?.isInStock || '';
  const quantity = localStoreLocation?.inventory?.quantity;
  const formattedInventoryQty = formatInventory(quantity);
  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };
  const isZeroInStock = isCartTiles && formattedInventoryQty <= 0;
  return (
    <div>
      <TileHead title="Pickup" automationId={IDS.LABEL.pickupLabel} />
      <div className="">
        <div data-testid="ffm-details-row" className="sui-pt-2">
          {isInStock && (
            <>
              <Typography variant="body-base" weight="bold">
                <div
                  className="sui-font-bold sui-min-h-[24px] sui-pb-1"
                  data-testid="pickupTimeline"
                  data-automation-id={IDS.ETA.pickupETA}
                >
                  {deliveryTimeline ? (
                    showTodayOrTomorrow(
                      deliveryTimeline,
                      true,
                      deliveryTimeline,
                      formatDate(deliveryTimeline)
                    )
                  ) : (
                    showBopisDate(pickupStoreHoursWithTZ)
                  )}
                </div>
              </Typography>
              {
                !fallbackMode && !configuratorHideQuantity
                && (
                  <Typography variant="body-base">
                    <div
                      data-testid="fulfillment-qty-row"
                      className="sui-min-h-[24px]"
                      data-automation-id={IDS.QTY.pickupQty}
                    >
                      <span className="sui-text-success">{formattedInventoryQty}</span> in stock
                    </div>
                  </Typography>
                )
              }
            </>
          )}
        </div>
        <TileFooter className="sui-font-bold">
          <span className="sui-text-success">FREE</span>
        </TileFooter>

      </div>
    </div>

  );
};

DefaultBOPIS.propTypes = {
  localStoreLocation: shape({
    isAnchor: bool,
    inventory: shape({
      quantity: number,
      isInStock: bool,
      isLimitedQuantity: bool,
      isBackordered: bool
    })
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  isCartTiles: bool,
  fallbackMode: bool,
  configuratorHideQuantity: bool,
  deliveryTimeline: string
};

DefaultBOPIS.defaultProps = {
  isCartTiles: false,
  pickupStoreHours: null,
  fallbackMode: false,
  configuratorHideQuantity: false,
  deliveryTimeline: null
};

export { DefaultBOPIS };
