/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { string, bool } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

const TileHead = ({ title, icon, isUnavailable, automationId = '' }) => {

  return (
    <div data-testid="tileHeading" data-automation-id={automationId}>
      <div className="sui-font-bold sui-text-xl md:sui-text-2xl sui-pt-4">
        {title}
      </div>
      { icon && (<span className={`${icon}`} />)}
    </div>
  );
};
TileHead.propTypes = {
  title: string,
  icon: string,
  automationId: string,
  isUnavailable: bool
};
TileHead.defaultProps = {
  title: '',
  icon: '',
  automationId: '',
  isUnavailable: false
};

export { TileHead };
