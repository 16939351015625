/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  formatDate,
  formatInventory,
  showTodayOrTomorrow,
  showDateOrDateRange
} from '../../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { IDS } from '../../../../automationTestIds';

const DefaultBoSS = ({
  fulfillment, backordered, fallbackMode, isSpecialOrder, configuratorHideQuantity, isCartTiles
}) => {
  const bossLocation = (fulfillment?.locations || []).find((location) => location.type === 'online');
  const quantity = bossLocation?.inventory?.quantity;
  const formattedInventoryQty = formatInventory(quantity);
  const startDate = fulfillment?.deliveryDates?.startDate;
  const endDate = fulfillment?.deliveryDates?.endDate;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const deliveryEndDate = fulfillment?.deliveryDates?.endDate;
  const deliveryTimeline = fulfillment?.deliveryTimeline;
  return (

    <div>
      <TileHead title="Ship to Store" automationId={IDS.LABEL.stsLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" weight="bold">
          {(startDate || endDate) && (
            formattedStartDate === formattedEndDate ? (
              <div className="sui-font-bold sui-min-h-[24px] sui-pb-1" data-automation-id={IDS.ETA.stsETA}>
                {showTodayOrTomorrow(deliveryEndDate, true, deliveryTimeline, formattedEndDate)}
              </div>
            ) : (
              <div className="sui-font-bold sui-min-h-[24px] sui-pb-1" data-automation-id={IDS.ETA.stsETA}>
                {showDateOrDateRange({ formattedStartDate, formattedEndDate })}
              </div>
            )
          )}
          {(!startDate && !endDate) && (
            <div className="sui-font-bold sui-min-h-[24px] sui-pb-1" data-automation-id={IDS.ETA.stsETA}>
              &nbsp;
            </div>
          )}
        </Typography>
        <Typography variant="body-base">
          {backordered ? (
            <div
              className={'sui-min-h-[24px]' + (!isCartTiles ? ' sui-pt-1' : '')}
              data-automation-id={IDS.QTY.stsQty}
              data-testid="fulfillment-qty-row"
            >
              Backordered
            </div>
          ) : (((!fallbackMode && !fulfillment?.isBlindsItem) && (!isSpecialOrder && !configuratorHideQuantity))
        && (
          <div
            className={'sui-min-h-[24px]' + (!isCartTiles ? ' sui-pt-1' : '')}
            data-testid="fulfillment-qty-row"
            data-automation-id={IDS.QTY.stsQty}
          >
            <span className="sui-text-success">{formattedInventoryQty}</span> available
          </div>
        )
          )}
          {fulfillment?.isBlindsItem && (
            <div
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="sui-min-h-[24px] sui-pb-1"
              data-testid="fulfillment-qty-row"
              data-automation-id="isBLindsItem"
            >&nbsp;
            </div>
          )}
        </Typography>

      </div>
      <TileFooter className="sui-font-bold">
        <span className="sui-text-success">FREE</span>
      </TileFooter>
    </div>

  );
};

DefaultBoSS.propTypes = {
  backordered: bool,
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  fallbackMode: bool,
  isSpecialOrder: bool,
  configuratorHideQuantity: bool,
  isCartTiles: bool
};

DefaultBoSS.defaultProps = {
  backordered: false,
  fallbackMode: false,
  isSpecialOrder: false,
  configuratorHideQuantity: false,
  isCartTiles: false
};

export { DefaultBoSS };
