/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf,
  object
} from 'prop-types';
import {
  Typography, Link, Popover
} from '@one-thd/sui-atomic-components';
import { WarningFilled, InfoFilled } from '@one-thd/sui-icons';

import { CHECK_NEARBY_STORES, FULFILLMENT_METHODS } from '../../../constants';
import { isMobile, isDesktop } from '../../../helper/utils';
import { CheckNearByStore } from './CheckNearByStore';

const DefaultBOPISDetails = ({
  fulfillment,
  itemId,
  isShipToHomeEligible,
  quantity,
  bossFulfillment,
  channel,
  pricing,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  isCartTiles,
  addOnsAssemblyAndMore
}) => {
  const [bossDrawerOpen, setBossDrawerOpen] = useState(false);
  const localStoreLocation = (fulfillment?.locations || []).find((location) => location.isAnchor);
  const isBopisThresholdStore = bopisMinimumThresholdStores?.split(',').indexOf(localStoreLocation?.locationId) !== -1;
  const showMinimumThresholdMessage = (quantity * pricing?.value) < bopisMinimumThreshold;

  // Change the drawer to popover
  const toggleDrawer = () => {
    setBossDrawerOpen(!bossDrawerOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderPopover = () => (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      placement="bottom"
      title="Need More?"
      style={{ zIndex: 900 }}
    >
      <Typography variant="body-base">
        <div>
          <span>Just enter the quantity you need and we will ship it to</span>
          <span className="sui-font-bold"> {localStoreLocation?.storeName} </span>
          <span>for free pickup.</span>
        </div>
      </Typography>
      <Typography variant="body-base">
        <div className="sui-pt-2">
          <span className="sui-font-bold">{'Need it even sooner? '}</span>
          <CheckNearByStore
            itemId={itemId}
            addOnsAssemblyAndMore={addOnsAssemblyAndMore}
            isShipToHomeEligible={isShipToHomeEligible}
          >
            <Link classNamecomponent="span" onClick={toggleDrawer}>{ CHECK_NEARBY_STORES }</Link>
          </CheckNearByStore>
                &nbsp; to see if another store has enough items in stock.
        </div>
      </Typography>
    </Popover>
  );

  const renderBossToolTip = () => (
    <div className="sui-flex-row sui-pt-3">
      <Typography variant="body-base">
        {'Need to pickup more? '}
        <Link
          classNamecomponent="span"
          data-testid="need-to-pickup-more"
          aria-describedby={id}
          onClick={handleClick}
          tabIndex="0"
        >
          {' We can ship to your store!'}
        </Link>
      </Typography>
      <span data-testid="boss-drawer">
        {renderPopover()}
      </span>
    </div>
  );

  return localStoreLocation?.inventory?.isInStock && (
    <div>
      <div>
        {
          isCartTiles && fulfillment?.isBossDominant && (
            <span className="sui-flex sui-pt-2">
              <div className="sui-pr-1 sui-pt-1">
                <InfoFilled
                  size="small"
                  style={{ color: '#54B4AC' }}
                />
              </div>
              <Typography variant="body-base">
                {'Need more? Just enter the quantity you need and we will ship it to '}
                <span className="sui-font-bold">
                  {localStoreLocation?.storeName}
                </span>
                {' for FREE Pickup.'}
              </Typography>
            </span>
          )
        }
        {!isCartTiles && bossFulfillment && isMobile(channel) && (
          renderBossToolTip()
        )}
        {!isCartTiles && bossFulfillment && isDesktop(channel) && (
          renderBossToolTip()
        )}
      </div>
      {showMinimumThresholdMessage && isBopisThresholdStore && (
        <div className="sui-pt-1">
          <div className="sui-flex sui-flex-wrap">
            <WarningFilled color="warning" />
            <Typography variant="body-base">
              <div className="sui-pl-2">
                <span>There&apos;s a ${bopisMinimumThreshold} minimum purchase for pickup.</span>
              </div>
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

DefaultBOPISDetails.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  quantity: number,
  channel: string,
  bossFulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }),
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  pricing: shape({
    value: number
  }).isRequired,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string,
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: object
};

DefaultBOPISDetails.defaultProps = {
  isShipToHomeEligible: false,
  quantity: 1,
  bossFulfillment: null,
  channel: 'desktop',
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null,
  isCartTiles: false,
  addOnsAssemblyAndMore: null
};

export { DefaultBOPISDetails };