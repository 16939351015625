/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import dayjs from 'dayjs';
import { Typography } from '@one-thd/sui-atomic-components';
import { ShippingThreshold } from './ShippingThreshold';
import {
  formatDate,
  showTodayOrTomorrow,
  showDateOrDateRange,
  formatInventory
} from '../../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { useAssemblyListeners } from '../../../../hooks/useAssemblyListeners';
import { FULFILLMENT_METHODS } from '../../../constants';
import { IDS } from '../../../../automationTestIds';

const DefaultShipping = ({
  deliveryStartDate,
  deliveryEndDate,
  deliveryTimeline,
  type,
  hasFreeShipping,
  totalCharge,
  maxInventoryAvail,
  backordered,
  maxCartPriceContributor,
  selected,
  isCartTiles,
  testID,
  isSpecialOrder,
  configuratorHideQuantity,
  isBodfsGrillsAssemblyEligible,
  hideShippingThreshold,
  isBlindsItem
}) => {
  const isBodfsGrillsAssemblyEnabled = useConfigService('bodfsGrillsAssemblyEnabled');
  const bodfsAssemblyEtaEnabled = useConfigService('bodfsAssemblyEtaEnabled') || false;
  const { isBODFSAssemblySelected } = useAssemblyListeners({
    isBodfsGrillsAssemblyEnabled,
    isBodfsGrillsAssemblyEligible
  });
  const isDeliveryWithAssemblySku = type === FULFILLMENT_METHODS.EXPRESS_DELIVERY && isBODFSAssemblySelected;
  // for adding 2 days to deliveries that are BODFS and have pre assembly selected
  const deliveryStartDateAdj = bodfsAssemblyEtaEnabled && isDeliveryWithAssemblySku
    ? dayjs(deliveryStartDate).add(2, 'day').format('YYYY-MM-DD')
    : deliveryStartDate;
  const deliveryEndDateAdj = bodfsAssemblyEtaEnabled && isDeliveryWithAssemblySku
    ? dayjs(deliveryEndDate).add(2, 'day').format('YYYY-MM-DD')
    : deliveryEndDate;

  const formattedStartDate = formatDate(deliveryStartDateAdj);
  const formattedEndDate = formatDate(deliveryEndDateAdj);
  const formattedInventoryQty = formatInventory(maxInventoryAvail);

  const showDeliveryStartDate = deliveryStartDateAdj || deliveryEndDateAdj;

  const renderDeliveryStartDate = () => {
    if ((!bodfsAssemblyEtaEnabled && type === FULFILLMENT_METHODS.EXPRESS_DELIVERY && isBODFSAssemblySelected)
      || !showDeliveryStartDate) {
      return (
        <div
          className="sui-font-bold sui-min-h-[24px] sui-pb-1"
          data-automation-id={IDS.ETA.sthETA}
        >
          &nbsp;
        </div>
      );
    }

    if (formattedStartDate === formattedEndDate) {
      const timelineAdj = isBODFSAssemblySelected && isDeliveryWithAssemblySku ? 'later' : deliveryTimeline;
      return (
        <div
          className="sui-font-bold sui-min-h-[24px] sui-pb-1"
          data-automation-id={IDS.ETA.sthETA}
        >
          {showTodayOrTomorrow(deliveryEndDateAdj, true, timelineAdj, formattedEndDate)}
        </div>
      );
    }

    return (
      <div className="sui-font-bold sui-min-h-[24px] sui-pb-1" data-automation-id={IDS.ETA.sthETA}>
        {showDateOrDateRange({ formattedStartDate, formattedEndDate })}
      </div>
    );
  };

  return (
    <div className="" data-testid={testID}>
      <TileHead
        title="Delivery"
        automationId={IDS.LABEL.sthLabel}
      />
      <div className="delivery-tile-content">
        <div data-testid="ffm-details-row" className="sui-pt-2">
          <Typography variant="body-base" weight="bold">
            {renderDeliveryStartDate()}
          </Typography>
          <Typography variant="body-base">
            {backordered && type === FULFILLMENT_METHODS.STH_FFM ? (
              <div
                data-testid="fulfillment-qty-row"
                className="sui-min-h-[24px]"
                data-automation-id={IDS.QTY.sthQty}
              >
                Backordered
              </div>
            ) : (
              <div
                data-testid="fulfillment-qty-row"
                className={isBlindsItem ? 'sui-min-h-[28px]' : 'sui-min-h-[24px]'}
                data-automation-id={IDS.QTY.sthQty}
              >
                {!!formattedInventoryQty && (!isSpecialOrder && !configuratorHideQuantity) && (
                  <>
                    <span className="sui-text-success">{formattedInventoryQty}</span> available
                  </>
                )}
              </div>
            )}
          </Typography>

        </div>
        <TileFooter className="sui-font-bold" isDelivery>
          {!hideShippingThreshold
            && (
              <ShippingThreshold
                type={type}
                hasFreeShipping={hasFreeShipping}
                totalCharge={totalCharge}
                maxCartPriceContributor={maxCartPriceContributor}
                selected={selected}
                isCartTiles={isCartTiles}
              />
            )}
          {hideShippingThreshold && <span className="sui-pt-2 sui-min-h-[24px]">&nbsp;</span>}
        </TileFooter>

      </div>
    </div>

  );
};

DefaultShipping.propTypes = {
  deliveryStartDate: PropTypes.string,
  deliveryEndDate: PropTypes.string,
  deliveryTimeline: PropTypes.string,
  type: PropTypes.string,
  hasFreeShipping: PropTypes.bool,
  totalCharge: PropTypes.number,
  maxInventoryAvail: PropTypes.number,
  backordered: PropTypes.bool,
  maxCartPriceContributor: PropTypes.string,
  selected: PropTypes.bool,
  isCartTiles: PropTypes.bool,
  testID: PropTypes.string,
  isSpecialOrder: PropTypes.bool,
  configuratorHideQuantity: PropTypes.bool,
  isBodfsGrillsAssemblyEligible: PropTypes.bool,
  hideShippingThreshold: PropTypes.bool,
  isBlindsItem: PropTypes.bool
};

DefaultShipping.defaultProps = {
  deliveryStartDate: null,
  deliveryEndDate: null,
  deliveryTimeline: null,
  type: null,
  hasFreeShipping: false,
  totalCharge: null,
  maxInventoryAvail: null,
  backordered: false,
  maxCartPriceContributor: null,
  selected: false,
  isCartTiles: false,
  testID: '',
  isSpecialOrder: false,
  configuratorHideQuantity: false,
  isBodfsGrillsAssemblyEligible: false,
  hideShippingThreshold: false,
  isBlindsItem: false
};

export { DefaultShipping };
