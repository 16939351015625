import React from 'react';
import PropTypes from 'prop-types';
import { InfoFilled } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';

export const StoreOnlyAlert = () => {
  return (
    <>
      <div className="sui-pt-1">
        <div className="sui-flex">
          <span className="sui-mt-1">
            <InfoFilled size="small" style={{ color: '#54B4AC' }} />
          </span>
          <Typography variant="body-base">
            <div className="sui-pl-2">
              <span>This item isn&apos;t available for Pickup online, but can still be purchased in-store.</span>
            </div>
          </Typography>
        </div>
      </div>
    </>
  );
};
