/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import {
  string,
  shape,
  number,
  object,
  bool
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { formatDistance } from '../../../helper/utils';
import { getDefaultStoreHours, showBopisDate } from '../../../helper/timeZoneUtils';
import { IDS } from '../../../../automationTestIds';

const InStockNearByStore = ({ nearByStoreLocation, pickupStoreHours, isCartTiles }) => {
  // Need to use the nearbyStoreHours from the API once in place and remove the useStore .
  // Until then we are taking storeHours from the experience-context.
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };
  return (
    <div>
      <TileHead title="Pickup Nearby" automationId={IDS.LABEL.pickupNearByLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" weight="bold">
          <div
            className="sui-font-bold sui-pb-1 sui-min-h-[24px]"
            data-testid="pickupTimeline"
            data-automation-id={IDS.ETA.pickupNearByETA}
          >
            {showBopisDate(pickupStoreHoursWithTZ)}
          </div>
        </Typography>
        <Typography variant="body-base">
          <div className="sui-min-h-[24px] sui-line-clamp-1 sui-break-all sui-m-w-[100%]">
            <span
              data-testid="fulfillment-qty-row"
              data-automation-id={IDS.QTY.pickupNearByQty}
            >
              <span className="sui-text-success">{'In stock '}</span>
              {`${formatDistance(nearByStoreLocation?.distance)} mi away`}
            </span>
          </div>
        </Typography>

      </div>
      <TileFooter className="sui-font-bold">
        <span className="sui-text-success">FREE</span>
      </TileFooter>
    </div>

  );
};
InStockNearByStore.propTypes = {
  nearByStoreLocation: shape({
    storeName: string,
    distance: number
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  isCartTiles: bool
};

InStockNearByStore.defaultProps = {
  isCartTiles: false,
  pickupStoreHours: null
};

export { InStockNearByStore };
