/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import {
  bool, number, shape, string, object
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { Typography } from '@one-thd/sui-atomic-components';
import { InfoFilled } from '@one-thd/sui-icons';
import { TileHead } from '../../TileHead';
import { getDefaultStoreHours, showBopisDate } from '../../../../helper/timeZoneUtils';
import { IDS } from '../../../../../automationTestIds';

const BopisNotFulfillableOOS = ({
  isCartTiles, location, pickupStoreHours
}) => {
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }

  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };

  return (
    <div className="">
      <div className="sui-flex sui-items-center">
        <TileHead title="At Your Store" isUnavailable automationId={IDS.LABEL.atYourStoreLabel} />
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <div className="sui-pt-[16px]" data-testid="AtYourStoreInfoIcon">&nbsp;
          <InfoFilled size="small" style={{ color: '#54B4AC', verticalAlign: 'text-bottom' }} />
        </div>
      </div>
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" color="text-colors-inactive">
          <div
            className="sui-font-bold sui-min-h-[24px] sui-pb-1"
            data-testid="pickupTimeline"
            data-automation-id={IDS.ETA.atYourStoreETA}
          >
            {showBopisDate(pickupStoreHoursWithTZ)}
          </div>
        </Typography>
        <Typography variant="body-base" color="text-colors-inactive">
          <div className="sui-pb-2  sui-min-h-[24px] sui-text-[15px]">
            {/* // Will validate for cart and remove once confirmed and does not break anything for PIP */}
            <div className="sui-line-clamp-1 sui-break-all sui-m-w-[100%]">
              Visit {location?.storeName}
            </div>
            for availability
          </div>
        </Typography>
      </div>
    </div>

  );

};
BopisNotFulfillableOOS.propTypes = {
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  location: shape({
    storeName: string,
    distance: number
  }).isRequired
};
BopisNotFulfillableOOS.defaultProps = {
  isCartTiles: false,
  pickupStoreHours: null
};

export { BopisNotFulfillableOOS };
