import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const OffshoreBossUnavailable = ({ stateName }) => {
  return (
    <div className="">
      <TileHead title="Pickup" isUnavailable automationId={IDS.LABEL.pickupLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" color="text-colors-inactive">
          Unavailable in {stateName}
        </Typography>
      </div>
    </div>
  );
};
OffshoreBossUnavailable.propTypes = {
  stateName: string
};
OffshoreBossUnavailable.defaultProps = {
  stateName: null
};

export { OffshoreBossUnavailable };
