import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { ArrowDown, ArrowUp } from '@one-thd/sui-icons';
import { getLongDescription } from '../../helpers/utils';

export const DeliveryOptionCard = ({
  deliveryType,
  deliveryOption,
  isBigBulkySthServices
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  }

  const renderCollapseLinkForBulkyItem = () => {
    return (
      <div className="sui-flex sui-items-center sui-ml-2">
        <Link
          onClick={handleCollapse}
          underline="none"
          component="button"
          type="button"
          className="sui-flex sui-cursor-pointer"
        >
          <div className="sui-pt-2">
            {isCollapsed ? (
              <ArrowDown
                size="small"
                color="primary"
              />
            ) : (
              <ArrowUp
                size="small"
                color="primary"
              />
            )}
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div data-automation-id="deliveryType" className="sui-pb-2">
      <Typography variant="body-base" height="normal" color="primary">
        <div className="sui-grid sui-grid-cols-12">
          <div className="sui-col-span-10 sui-pb-1">
            <div className={`sui-items-center sui-ml-4 ${!isBigBulkySthServices ? '' : 'sui-flex'}`}>
              {getLongDescription(deliveryOption?.mode?.longDesc) && (
                <>
                  <span className="sui-font-bold">{deliveryOption?.mode?.desc}</span>
                  {isBigBulkySthServices ? (
                     renderCollapseLinkForBulkyItem()
                  ) : (
                    <>
                      : {' '}
                      <span className="sui-text-base">
                        {getLongDescription(deliveryOption?.mode?.longDesc)}
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="sui-col-span-2 sui-text-end sui-font-bold">
            {isBigBulkySthServices && deliveryOption?.totalCharge === 0
              ? <span className="sui-text-success">FREE</span> :
              (isBigBulkySthServices && `$${deliveryOption?.totalCharge}`)
            }
          </div>
        </div>
      </Typography>

      {!isCollapsed && (
        <Typography variant="body-base" height="normal">
          <div className="sui-grid sui-grid-cols-12">
            <div className="sui-col-span-10 sui-pb-1">
              <div className="sui-flex sui-items-center sui-ml-4">
                {getLongDescription(deliveryOption?.mode?.longDesc)}
              </div>
            </div>
          </div>
        </Typography>
      )}
    </div>
  );
}

DeliveryOptionCard.propTypes = {
  deliveryType: PropTypes.string.isRequired,
  deliveryOption: PropTypes.PropTypes.shape({
    mode: PropTypes.shape({
      desc: PropTypes.string,
      longDesc: PropTypes.string
    }),
    totalCharge: PropTypes.number,
  }),
};

DeliveryOptionCard.defaultProps = {
  deliveryOption: null,
};
