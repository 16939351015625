import React from 'react';
import PropTypes from 'prop-types';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { Alert } from '@one-thd/sui-atomic-components';
import { showTodayOrTomorrow } from '../../../helper/utils';

const DetaCountDownTimer = ({
  dynamicEtaMinutes,
  dynamicEtaHours,
  showCountdownTime,
  inventoryQuantity,
  removeTimer,
  deliveryStartDate,
  isLongDayName,
  deliveryTimeline,
  startDate,
  zipCode,
  isCartTiles
}) => {
  const renderDetaTimerContent = () => (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="sui-text-base sui-flex sui-flex-wrap detaCountDownTimerFont">
      <span>Order within</span>
      &nbsp;
      <CountdownTimer
        hours={dynamicEtaHours}
        minutes={dynamicEtaMinutes}
        onRemove={removeTimer}
        showCondensedTimer
      />
      &nbsp;
      <span>to get it by</span>
      <span className="sui-font-bold sui-text-sm sui-whitespace-nowrap">
        &nbsp;
        {showTodayOrTomorrow(
          deliveryStartDate,
          isLongDayName,
          deliveryTimeline,
          startDate
        )}
      </span>
      {!inventoryQuantity && zipCode && (
        <>
          <span>&nbsp;to&nbsp;</span>
          <span className="sui-font-bold">{zipCode}</span>
        </>
      )}
    </div>
  );

  return dynamicEtaMinutes && showCountdownTime ? (
    <Alert
      className={(!isCartTiles ? 'sui-mb-4 ' : ' ')
        + 'sui-border-1 sui-border-solid sui-border-primary sui-rounded-base'}
      data-component="DetaCountDownTimer"
    >
      {renderDetaTimerContent()}
    </Alert>
  ) : null;

};

DetaCountDownTimer.propTypes = {
  dynamicEtaMinutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dynamicEtaHours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCountdownTime: PropTypes.bool,
  inventoryQuantity: PropTypes.number,
  removeTimer: PropTypes.func,
  deliveryStartDate: PropTypes.string,
  isLongDayName: PropTypes.bool,
  deliveryTimeline: PropTypes.string,
  startDate: PropTypes.string,
  zipCode: PropTypes.string,
  isCartTiles: PropTypes.bool,
};

DetaCountDownTimer.defaultProps = {
  dynamicEtaMinutes: null,
  dynamicEtaHours: '0',
  showCountdownTime: true,
  inventoryQuantity: null,
  removeTimer: {},
  deliveryStartDate: null,
  isLongDayName: true,
  deliveryTimeline: null,
  startDate: null,
  zipCode: null,
  isCartTiles: false
};

export { DetaCountDownTimer };
