import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { CheckNearByStore } from '../partials/store/CheckNearByStore';
import { track } from '../../../analytics';

const ExcludedShipState = ({
  stateText,
  itemId,
  originalConfigId,
  checkAvailabilityComponent,
  showCheckAvailability,
  setShowCheckAvailability,
  addOnsAssemblyAndMore
}) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
    track('click', {
      origin: 'delivery-options',
      eventName: 'check fulfillment availability',
      primaryCategory: 'button submission'
    });
  };

  const availabilityButtonStencil = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      classNamecomponent="span"
      onClick={handleOnClick}
    >
      ZIP Code
    </Link>
  );

  return (
    <div className="sui-max-w-md" data-testid="fulfillment__unavailable" data-component="FulfillmentExcludedShipState">
      <Typography variant="h3" height="tight" weight="bold">{`Not Available in ${stateText}`}</Typography>
      <Typography variant="body-base">
        <div className="sui-pt-2">
          {'Please '}
          <CheckNearByStore
            itemId={itemId}
            originalConfigId={originalConfigId}
            addOnsAssemblyAndMore={addOnsAssemblyAndMore}
          >
            change your store
          </CheckNearByStore>
          {' or '}
          {availabilityButtonStencil}
          {' to purchase'}
        </div>
      </Typography>
      {showCheckAvailability && (checkAvailabilityComponent)}
    </div>

  );
};

ExcludedShipState.propTypes = {
  stateText: string.isRequired,
  itemId: string.isRequired,
  originalConfigId: string,
  checkAvailabilityComponent: PropTypes.element.isRequired,
  showCheckAvailability: PropTypes.bool.isRequired,
  setShowCheckAvailability: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: PropTypes.object
};

ExcludedShipState.defaultProps = {
  addOnsAssemblyAndMore: null,
  originalConfigId: null
};

export { ExcludedShipState };
