import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { WarningFilled } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';
import { setErrorEvents } from '../../../analytics';

export const BackorderedAlert = ({ isMajorAppliance = false }) => {
  useEffect(() => {
    setErrorEvents({ message:
      `This product is currently backordered.${!isMajorAppliance ? ' We won\'t charge you until it ships.' : ''}` });
  }, []);

  return (
    <div className="sui-pt-2">
      <div className="sui-flex">
        <span className="sui-mt-1 sui-w-6">
          <WarningFilled color="warning" />
        </span>
        <Typography variant="body-base">
          <div className="sui-pl-2">
            <span>This product is currently</span>
            <span className="sui-font-bold">&nbsp;backordered.</span>
            {!isMajorAppliance && <span>&nbsp;{'We won\'t charge you until it ships.'}</span>}
          </div>
        </Typography>
      </div>
    </div>

  );
};

BackorderedAlert.propTypes = {
  isMajorAppliance: PropTypes.bool
};

BackorderedAlert.defaultProps = {
  isMajorAppliance: false
};