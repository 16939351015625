import React from 'react';
import {
  string,
  shape,
  number
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const LimitedStockByStore = ({ location }) => {
  return (
    <div>
      <TileHead title="Limited Stock" isUnavailable automationId={IDS.LABEL.limitedStockLabel} />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        <Typography variant="body-base" color="text-colors-inactive">
          <div className="sui-pb-2">
            <span>{`Visit ${location?.storeName} for availability`}</span>
          </div>
        </Typography>
      </div>
    </div>

  );
};

LimitedStockByStore.propTypes = {
  location: shape({
    storeName: string,
    distance: number
  }).isRequired
};

LimitedStockByStore.defaultProps = {};

export { LimitedStockByStore };
