/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { ShippingThreshold } from './ShippingThreshold';
import {
  formatDate,
  formatWeek
} from '../../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { FULFILLMENT_METHODS } from '../../../constants';
import { IDS } from '../../../../automationTestIds';

export const ApplianceShipping = ({
  deliveryStartDate,
  hasFreeShipping
}) => {
  const startDate = formatDate(deliveryStartDate);
  const formattedEarliestDelivery = formatWeek(deliveryStartDate, false, startDate);
  return (

    <div>
      <TileHead
        title="Delivery"
        automationId={IDS.LABEL.sthLabel}
      />
      <div data-testid="ffm-details-row" className="sui-pt-2">
        {deliveryStartDate && (
          <>
            <Typography variant="body-base">
              <div
                className="sui-min-h-[24px] sui-pb-1"
                data-automation-id={IDS.ETA.sthETA}
              >
                Earliest delivery
              </div>
            </Typography>
            <Typography variant="body-base" weight="bold">
              <div
                data-testid="fulfillment-qty-row"
                className="sui-min-h-[24px]"
                data-automation-id={IDS.QTY.sthQty}
              >
                {formattedEarliestDelivery}
              </div>
            </Typography>

          </>
        )}
      </div>
      <TileFooter className="sui-font-bold" isDelivery>
        <ShippingThreshold
          type={FULFILLMENT_METHODS.APPLIANCE}
          hasFreeShipping={hasFreeShipping}
        />
      </TileFooter>
    </div>

  );
};

ApplianceShipping.propTypes = {
  deliveryStartDate: PropTypes.string,
  hasFreeShipping: PropTypes.bool
};

ApplianceShipping.defaultProps = {
  deliveryStartDate: null,
  hasFreeShipping: false
};
